import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { Chart, ChartTypeRegistry, TooltipModel } from 'chart.js';
import { COLORS } from '../../../shared/consts/colors';
import { TranslateService } from '@ngx-translate/core';

export interface ITooltipContent {
    rows: {
        color: string;
        label: string;
        percentage: string | number | undefined;
        value: string | number | undefined;
    }[];
}

@Injectable({
    providedIn: 'root',
})
export class TooltipService {
    content: WritableSignal<ITooltipContent> = signal({ rows: [] });
    position: WritableSignal<Record<string, string>> = signal({});
    leftDisplay: WritableSignal<boolean> = signal(false);
    show: WritableSignal<boolean> = signal(false);
    chartClass: WritableSignal<string> = signal('');
    _COLORS = COLORS;

    translateService: TranslateService = inject(TranslateService);

    positionTooltip(x: number, y: number) {
        const offsetX = 15;
        const offsetY = 0;
        this.position.set({
            top: `${y + offsetY}px`,
            left: `${x + offsetX}px`,
        });
    }

    externalChartjsTooltipHandler = (
        context: {
            chart: Chart;
            tooltip: TooltipModel<keyof ChartTypeRegistry>;
        },
        tooltipSelector: string
    ) => {
        const { tooltip, chart } = context;

        const element = document.querySelector(
            `${tooltipSelector} .chart-js-tooltip`
        ) as HTMLElement;

        if (element) {
            element.innerHTML = `
            <div class="arrow"></div>
            <div class="date"></div>
            <div class="row">
                <div class="label">
                    <span class="color"></span>
                    <span class="name"></span>
                </div>
                <div class="percentage"></div>
                <div class="value"></div>
            </div>`;
        }

        if (tooltip.opacity === 0) {
            element.style.opacity = '0';
            return;
        }

        if (tooltip.body) {
            const bodyLines = tooltip.body.map(
                (b: Record<string, string[]>) => b['lines']
            );

            const row = <HTMLElement>element.querySelector('.row');
            const date = <HTMLElement>element.querySelector('.date');
            date.innerHTML = tooltip.title[0];

            for (const line of bodyLines) {
                const item = line[0];
                const arr = item.split(': ');
                const labelKey = arr[0].toUpperCase().replace(' ', '_');
                const value = arr[1];

                const newRow = <HTMLElement>row?.cloneNode(true);

                const nameLabel = newRow.querySelector('.label .name');
                if (nameLabel) {
                    const translatedLabel = this.translateService.instant(
                        `GRAPHS.LABELS.${labelKey}`
                    );
                    nameLabel.innerHTML = translatedLabel;
                }

                const percentageLabel = newRow.querySelector('.percentage');
                if (percentageLabel) {
                    percentageLabel.innerHTML = `${value} ${labelKey !== 'CRITICAL_POINTS' ? '%' : ''}`;
                }

                element.appendChild(newRow);
            }

            row?.remove();

            const { offsetLeft: positionX, offsetTop: positionY } =
                chart.canvas;
            let offset = 40;

            const chartWidth = chart.width;
            const tooltipWidth = element.offsetWidth;

            const tooltipPositionX = positionX + tooltip.caretX;

            const shouldDisplayLeft =
                tooltipPositionX + tooltipWidth > chartWidth;

            const tooltipHeight = element.offsetHeight;
            const tooltipPositionY = positionY + tooltip.caretY;

            const shouldDisplayAbove =
                tooltipPositionY + tooltipHeight / 2 - offset > chart.height;

            const shouldDisplayAboveBelow =
                tooltipPositionY + tooltipHeight / 2 - offset < chart.height;

            if (shouldDisplayAbove) {
                offset = offset * 3 + 10;
            }

            if (shouldDisplayAboveBelow) {
                offset = offset - 30;
            }

            element.style.opacity = '1';
            if (shouldDisplayLeft) {
                element.style.left = `${positionX + tooltip.caretX - 30}px`;
                element.style.transform = 'translateX(-100%)';
            } else {
                element.style.left = `${tooltipPositionX}px`;
                element.style.transform = 'translateX(30px)';
            }

            element.style.top = `${tooltipPositionY - offset}px`;

            this.styleChartJsTooltip(element, shouldDisplayLeft);
        }
    };

    styleChartJsTooltip(
        tooltipElement: HTMLElement,
        shouldDisplayLeft = false
    ) {
        const arrow = <HTMLElement>tooltipElement.querySelector('.arrow');
        const date = <HTMLElement>tooltipElement.querySelector('.date');

        if (date) {
            date.style.fontSize = '12px';
            date.style.fontWeight = 'bold';
        }

        if (arrow) {
            const after = document.createElement('div');
            const before = document.createElement('div');

            if (shouldDisplayLeft) {
                arrow.style.right = '0';
                arrow.style.transform = 'translate(35%, -50%)';
                after.style.right = '-1px';
                after.style.transform = 'rotate(-240deg)';
                before.style.right = '1px';
                before.style.transform = 'rotate(240deg)';
            } else {
                arrow.style.left = '0';
                arrow.style.transform = 'translate(-35%, -50%)';
                after.style.left = '-1px';
                after.style.transform = 'rotate(-330deg)';
                before.style.left = '1px';
                before.style.transform = 'rotate(330deg)';
            }

            arrow.style.position = 'absolute';
            arrow.style.top = '50%';
            arrow.style.zIndex = '1';
            arrow.style.height = '22px';
            arrow.style.width = '33px';
            arrow.style.borderRadius = '100%';
            arrow.style.background = '#FFF';

            after.style.width = '30px';
            after.style.height = '30px';
            after.style.position = 'absolute';
            after.style.top = '-25px';
            after.style.background = 'transparent';
            after.style.borderRadius = '50%';
            after.style.borderRight = '13px solid #FFF';
            after.style.borderTop = '14px solid transparent';

            before.style.width = '30px';
            before.style.height = '30px';
            before.style.position = 'absolute';
            before.style.top = '16px';
            before.style.background = 'transparent';
            before.style.borderRadius = '50%';
            before.style.borderRight = '13px solid #FFF';
            before.style.borderBottom = '14px solid transparent';

            arrow.appendChild(after);
            arrow.appendChild(before);
        }

        const rows = [...tooltipElement.querySelectorAll('.row')];

        for (const row of rows) {
            if (row instanceof HTMLElement) {
                row.style.display = 'grid';
                row.style.gridTemplateColumns = '90px 1fr auto';
                row.style.alignItems = 'center';
                row.style.color = '#BBBBBB';
                row.style.fontSize = '12px';
                row.style.fontWeight = 'bold';
                row.style.gap = '12px';
                row.style.position = 'relative';
                row.style.zIndex = '10';
                row.style.whiteSpace = 'nowrap';

                const label = <HTMLElement>row.querySelector('.label');

                if (label) {
                    label.style.display = 'flex';
                    label.style.alignItems = 'center';
                    label.style.gap = '5px';
                }

                const color = <HTMLElement>row.querySelector('.color');
                const nameText = <string>row.querySelector('.name')?.innerHTML;

                if (color) {
                    color.style.borderRadius = '50%';
                    color.style.width = '10px';
                    color.style.height = '10px';

                    switch (nameText) {
                        case 'Null':
                        case 'Nulo':
                            color.style.background =
                                this._COLORS['LABELS']['NULL'];
                            break;
                        case 'Low':
                        case 'Bajo':
                        case 'Baixo':
                            color.style.background =
                                this._COLORS['LABELS']['LOW'];
                            break;
                        case 'Moderate':
                        case 'Moderado':
                            color.style.background =
                                this._COLORS['LABELS']['MODERATE'];
                            break;
                        case 'High':
                        case 'Alto':
                            color.style.background =
                                this._COLORS['LABELS']['HIGH'];
                            break;
                        case 'Very High':
                        case 'Muy Alto':
                        case 'Muito Alto':
                            color.style.background =
                                this._COLORS['LABELS']['VERY_HIGH'];
                            break;
                        case 'Extreme':
                        case 'Extremo':
                            color.style.background =
                                this._COLORS['LABELS']['EXTREME'];
                            break;
                        case 'Critical Points':
                        case 'Puntos Críticos':
                        case 'Pontos Críticos':
                            color.style.background =
                                this._COLORS['LABELS']['CRITICAL_POINTS'];
                            break;
                    }
                }
            }
        }
    }
}
